import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { lazy } from "react";
import { ToastContainer, Bounce } from "react-toastify";

const Home = lazy(() => import("./pages/Home"));
const Privacy = lazy(() => import("./pages/Privacy"));
const NotFound = lazy(() => import("./pages/NotFound"));
const Cookies = lazy(() => import("./pages/Cookies"));
const CookieNotifier = lazy(() => import("./components/CookieNotifier"));

function App() {
  return (
    <>
      <div className="overflow-hidden">
        <HelmetProvider>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
            transition={Bounce}
          />
          <Router>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/cookies" element={<Cookies />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Router>
          <CookieNotifier />
        </HelmetProvider>
      </div>
    </>
  );
}

export default App;
